import React from "react"
import { Link } from "gatsby"


const molddesign = () => {
    return (
        <>
            <section>
                <div className="parallax">
                    <img src="/img/black-illustration-on-white-printer-paper-716661.jpg" alt="" />
                    <div className="overlay"></div>
                    <div className="headercontent">
                        <h1>MOLD DESIGN</h1>
                    </div>
                </div>
            </section>
            <section className="cad">
                <div className="container pt-4">
                    <p>Innoway Systems is one of the reputed tool design house for Plastic components. We are specialized in plastic mould components for automotive industry. We have tooling experience over 19K engineering hours in tool design and tool making. We have experience in handling most of the engineering plastics used in the industry. Our tools are running with near zero down time. We take up the molds that are challenging to our skills.</p>

                    <div className="row">
                        <div className="preview col-md-3">
                            <div className="preview-pic tab-content ">
                                <img src="/img/tool11-300x190-1.jpg" id="moldimage" alt="Products" width="315" height="295"></img>
                            </div>
                        </div>
                        <div className="preview col-md-3">
                            <div className="preview-pic tab-content">
                                <img src="/img/Photo-link-300x218-1.png" id="moldimage" alt="Products" width="315" height="295"></img>
                            </div>
                        </div>
                        <div className="preview col-md-3">
                            <div className="text-center">
                                <div ><img src="/img/tool2.jpg" id="moldimage" alt="Products" width="315" height="295"></img><br /><br /><br /></div>
                            </div>
                        </div>
                        <div className="preview col-md-3">
                            <div className="text-center">
                                <div ><img src="/img/Tooling-plan-300x73-1.jpg" id="moldimage" alt="Products" width="315" height="295"></img><br /><br /><br /></div>
                            </div>
                        </div>
                        <div className="text-center">
                            <p>We do spent reasonable hours in the design phase to ensure the tool life, trouble free running, cavity optimization to control the component cost.</p>
                            <p>We are equipped with the complete services for the plastic molding. The tooling development process is shown below.</p>
                            <p>The tooling development process review systems keeps our customers well informed about the development status, also we work on limited projects at a given point of time in-order to keep up the schedule and deliveries.</p>
                            <Link to="/contactus/" className="btn btn-danger">Contact Now</Link><br /> <br />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default molddesign
