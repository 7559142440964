import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MoldDesignPage from "../../components/services/molddesign/index"
const molddesign = () => {
  return (
    <Layout>
      <SEO
        title="Mold Design in India - Innoway System"
        desc="Innoway Systems is one of the reputed tool design house for Plastic components. We are specialized in plastic mould components for automotive industry."
        pathname="/services/mold-design/"
        keywords="Mold Design in coimbatore,Mold Design in TamilNadu"
      />
      <MoldDesignPage />
    </Layout>
  )
}

export default molddesign
